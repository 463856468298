.request_information_table{
  margin-bottom: 2rem;
  tr {
    td{
      border: none;
      padding: 5px;
      &:first-child{
        width: 140px;
        font-weight: 700;
      }
      &:last-child{
        code {
          display: block;
        }
      }
    }
  }
}