body {
  margin: 0;
  padding: 0;
}


.loader {
  width: auto !important;
}

.fixed-height-card-body {
  max-height: 400px;
  overflow-y: auto;
}

.no_padding {
  padding: 0 !important;
}

.file_error {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ff0000;
}
