.kyc_widget {
  &__container {

  }

  &__iframe {
    border: none;
    width: 100%;
    display: block;
    min-height: 600px;
  }
}
