.user {
  &__col {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-radius: 3px;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__content {
    flex: 1 1;
  }

  &__id {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__additional_information {
    margin-top: 20px;

    span {
      display: block;
      color: #9aa0ac;
      font-size: 0.9em;
    }

    &__title {
      font-weight: 700;
      color: #9aa0ac;
    }
  }

}

.profile__pic {
  width: 100%;
  height: 170px;

  span {
    position: relative;
    width: 140px;
    height: 140px;
    display: block;
    border-radius: 50%;
    margin: auto;
    margin-top: -110px;
    background-color: #ffffff;
    border: 3px solid #ffffff;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    i {
      position: absolute;
      right: 0px;
      top: 10px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      line-height: 30px;
      color: white;

      &.success {
        background-color: #5eba00;
      }

      &.danger {
        background-color: #cd201f;
      }
    }
  }


}


.blueish {
  background: linear-gradient(130deg, #91b5e3, #3581B8, #2F4858) !important;
}

.pinkish {
  background: linear-gradient(130deg, #f66d9b, #D81E5B) !important;
}

.magic__input {
  border-color: transparent;
  outline: none;
  background-color: transparent;
  transition: all ease-in-out .3s;
  padding: 0.3rem 0;
  display: block;
  width: 100%;

  &:focus {
    border-color: rgba(0, 40, 100, 0.12);
    padding: 0.3rem;
  }

  &::placeholder {
    text-decoration: underline;
  }
}